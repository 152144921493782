import { httpPost } from "@/api/request";
import { useStoreUser } from "@/store";

// 获取序列号规则列表
export function GET_SERIAL_NUMBER_RULE_LIST(params: object) {
    return httpPost("/sequence/rule/list", params, {
        headers: {
            Authorization: useStoreUser().getToken,
        },
    });
}
// 获取所有规则列表
export function GET_ALL_SERIAL_NUMBER_RULE_LIST(params: object) {
    return httpPost("/sequence/rule/allRule", params, {
        headers: {
            Authorization: useStoreUser().getToken,
        },
    });
}

// 获取序列号规则详情
export function GET_SERIAL_NUMBER_RULE_DETAIL(params: object) {
    return httpPost("/sequence/rule/detail", params, {
        headers: {
            Authorization: useStoreUser().getToken,
        },
    });
}

// 新增序列号规则
export function ADD_SERIAL_NUMBER_RULE(params: object) {
    return httpPost("/sequence/rule/add", params, {
        headers: {
            Authorization: useStoreUser().getToken,
        },
    });
}

// 编辑序列号规则
export function EDIT_SERIAL_NUMBER_RULE(params: object) {
    return httpPost("/sequence/rule/edit", params, {
        headers: {
            Authorization: useStoreUser().getToken,
        },
    });
}

// 删除序列号规则
export function DELETE_SERIAL_NUMBER_RULE(params: object) {
    return httpPost("/sequence/rule/delete", params, {
        headers: {
            Authorization: useStoreUser().getToken,
        },
    });
}

// 获取序列号列表
export function GET_SERIAL_NUMBER_LIST(params: object) {
    return httpPost("/sequence/serial/list", params, {
        headers: {
            Authorization: useStoreUser().getToken,
        },
    });
}

// 获取序列号详情
export function GET_SERIAL_NUMBER_DETAIL(params: object) {
    return httpPost("/sequence/serial/detail", params, {
        headers: {
            Authorization: useStoreUser().getToken,
        },
    });
}

// 新增序列号
export function ADD_SERIAL_NUMBER(params: object) {
    return httpPost("/sequence/serial/add", params, {
        headers: {
            Authorization: useStoreUser().getToken,
        },
    });
}

// 编辑序列号
export function EDIT_SERIAL_NUMBER(params: object) {
    return httpPost("/sequence/serial/edit", params, {
        headers: {
            Authorization: useStoreUser().getToken,
        },
    });
}

// 删除序列号
export function DELETE_SERIAL_NUMBER(params: object) {
    return httpPost("/sequence/serial/delete", params, {
        headers: {
            Authorization: useStoreUser().getToken,
        },
    });
}
